<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st7" d="M186.707,54.452c2.975,10.048,14.393,14.753,22.254,8.759c4.152-3.166,8.13-2.682,12.934-2.814   c-3.953,7.662-9.921,12.033-17.966,12.957c-13.603,1.562-24.91-8.145-25.913-21.774c-0.829-11.263,8.485-22.495,19.848-23.936   c12.244-1.553,23.889,6.752,25.866,18.447c1.376,8.139,1.191,8.362-6.936,8.362C206.85,54.452,196.905,54.452,186.707,54.452z    M186.766,46.596c9.081,0,18.099-0.009,27.116,0.006c2.301,0.004,1.412-1.259,0.97-2.341c-2.312-5.663-8.376-9.232-15.01-8.845   C193.69,35.775,188.424,40.196,186.766,46.596z"/>
            <path class="st7" d="M38.012,68.301C23.427,79.286,5.318,71.579,1.083,56.831C-2.657,43.81,5.6,30.482,19.213,27.749   c12.531-2.515,25.381,6.978,26.768,19.847c0.857,7.954,0.142,15.942,0.391,23.91c0.051,1.626-0.619,2.176-2.125,1.988   c-0.108-0.013-0.218-0.004-0.328-0.001C38.585,73.666,38.585,73.666,38.012,68.301z M38.262,50.602   c0.03-8.523-6.524-15.194-14.945-15.211C14.966,35.374,8.084,42.1,7.945,50.414c-0.136,8.124,6.929,15.212,15.183,15.233   C31.62,65.67,38.232,59.096,38.262,50.602z"/>
            <path class="st7" d="M156.858,80.059c-2.425-1.008-4.342-1.762-6.222-2.595c-4.852-2.151-9.298-3.051-15.102-1.836   c-15.038,3.148-27.328-6.85-30.203-20.737c-2.661-12.855,6.391-26.9,19.411-29.899c16.782-3.866,32.094,8.296,32.114,25.518   C156.867,60.231,156.858,69.953,156.858,80.059z M144.041,65.249c0.078,1.117,0.225,1.652,0.131,2.141   c-0.623,3.241,1.563,2.707,3.537,2.864c2.263,0.18,2.707-0.733,2.663-2.785c-0.126-5.792,0.018-11.59-0.067-17.384   c-0.137-9.278-7.73-17.732-16.605-18.607c-10.604-1.046-19.328,4.933-21.579,14.787c-2.214,9.696,2.908,19.314,12.069,22.633   C131.251,71.456,137.659,69.962,144.041,65.249z"/>
            <path class="st7" d="M88.425,27.559c6.015-0.185,10.796,1.868,15.171,5.206c1.399,1.068,1.594,1.856,0.161,2.97   c-0.684,0.531-1.337,1.154-1.847,1.85c-1.446,1.973-2.635,1.89-4.668,0.388c-5.889-4.354-14.732-3.146-19.558,2.322   c-5.153,5.838-5.205,14.456-0.124,20.288c4.927,5.654,13.66,6.721,19.868,2.501c0.807-0.548,1.492-2.189,2.634-1.286   c1.78,1.406,3.265,3.193,4.786,4.898c0.069,0.078-0.66,1.027-1.143,1.414c-6.707,5.38-14.221,6.985-22.35,4.106   c-8.218-2.91-13.475-8.77-14.999-17.425C63.787,40.199,74.333,27.57,88.425,27.559z"/>
            <path class="st7" d="M162.795,46.261c0-7.868,0.08-15.737-0.05-23.602c-0.023-1.398-0.074-2.734-0.074-2.734h7.873v7.141h7.184   v8.155h-7.119c0,0-0.096,1.605-0.056,3.628c0.21,10.486-0.003,20.98,0.143,31.468c0.036,2.548-0.601,3.357-3.214,3.242   c-4.679-0.206-4.686-0.042-4.687-4.678C162.795,61.34,162.795,53.8,162.795,46.261z"/>
            <path class="st7" d="M60.043,46.929c0,7.866-0.066,15.732,0.043,23.597c0.03,2.126-0.302,3.096-2.782,3.034   c-5.288-0.133-5.292,0.017-5.292-5.413c0-15.076,0.071-30.153-0.067-45.227c-0.024-2.68,0.809-3.306,3.322-3.219   c4.771,0.166,4.775,0.029,4.775,4.942C60.043,32.071,60.043,39.5,60.043,46.929z"/>
            <path class="st7" d="M229.884,46.566c0-7.965,0.072-15.932-0.047-23.895c-0.033-2.223,0.51-3.04,2.883-2.994   c5.305,0.103,5.308-0.027,5.308,5.43c0,14.949-0.077,29.898,0.068,44.845c0.027,2.825-0.586,3.805-3.562,3.633   c-4.637-0.269-4.649-0.062-4.649-4.759C229.884,61.405,229.885,53.985,229.884,46.566z"/>
            <path class="st7" d="M130.869,37.4c7.6-0.012,13.526,5.878,13.478,13.396c-0.046,7.179-6.181,13.335-13.345,13.389   c-7.239,0.055-13.521-6.275-13.482-13.583C117.56,43.233,123.447,37.412,130.869,37.4z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'alcatel-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
